<template>
  <div class="vg_wrapper">
    <add-header @submit="submit('mtrlForm')" @cancel="cancel" :stateFlag="true"></add-header>
    <el-row>
      <el-col :md="8" :offset="7" v-if="warningTitle">
        <div class="vd_color_red">！{{ warningTitle }}</div>
      </el-col>
    </el-row>
    <el-form ref="mtrlForm" :model="mtrlForm" :rules="rules" label-width="120px" size="mini">
      <el-row>
        <el-col :md="6">
          <el-form-item label="物料图片">
            <el-upload
              class="avatar-uploader"
              :action="uploadUrl"
              :data="dataBody"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
            >
              <imgPopover v-if="mtrlForm.imge_id" :imgeUrl="mtrlForm.imge_url" :imgeUrlO="mtrlForm.imge_urlO"></imgPopover>
              <i v-else class="el-icon-plus avatar-uploader-icon vg_pointer"></i>
            </el-upload>
            <span>仅支持jpg、png、jpeg格式且大小不能超过10MB</span>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="物料编号" prop="mtrl_no">
            <!-- <el-input v-model="mtrlForm.mtrl_no" @input ="mtrlForm.mtrl_no = helper.keepEngNum1(mtrlForm.mtrl_no)" placeholder="请填写账套编号（仅支持数字和英文和-）" minlength="30"/> -->
            <el-tooltip class="item" effect="dark" content="仅限两位大写英文字母" placement="top-start">
              <el-input class="mtrlNo" v-model="mtrlForm.mtrl_no" @input="mtrlNoInput" placeholder="例:CR" show-word-limit>
                <template slot="append">
                  {{ mtrlForm.mtrl_color }}
                  <span v-if="mtrlForm.mtrl_color">C</span>
                </template>
              </el-input>
            </el-tooltip>
          </el-form-item>
          <el-form-item label="物料名称" prop="mtrl_name">
            <el-input v-model="mtrlForm.mtrl_name" @change="mtrlNameChange" maxlength="30" show-word-limit placeholder="请填写物料名称">
              <template slot="prepend">{{ mtrlForm.colr_class }}</template>
            </el-input>
          </el-form-item>
          <el-form-item label="物料英文名" prop="mtrl_spec_en">
            <el-input v-model="mtrlForm.mtrl_spec_en" maxlength="50" show-word-limit placeholder="请填写物料英文名"> </el-input>
          </el-form-item>
          <el-form-item label="物料类型" prop="mtrl_type">
            <el-select v-model="mtrlForm.mtrl_type" placeholder="请选择物料类型" clearable @change="typeChange">
              <el-option v-for="item in mtrlType" :key="item.id" :label="item.label" :value="item.id"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="安全库存" prop="mtrl_stock_limit">
            <el-input
              v-model="mtrlForm.mtrl_stock_limit"
              @input="mtrlForm.mtrl_stock_limit = helper.keepTNum(mtrlForm.mtrl_stock_limit)"
              maxlength="7"
              show-word-limit
              placeholder="请填写库存警告数量"
            ></el-input>
          </el-form-item>
          <el-form-item label="物料单价" prop="mtrl_prod_price">
            <el-input
              disabled
              v-model="mtrlForm.mtrl_prod_price"
              @input="mtrlForm.mtrl_prod_price = helper.keepTNum1(mtrlForm.mtrl_prod_price)"
              @blur="mtrlForm.mtrl_prod_price = helper.calcPrice(mtrlForm.mtrl_prod_price, 7, 1000000)"
              maxlength="11"
              show-word-limit
              placeholder="请填写物料生产单价"
            >
              <template slot="append">元</template>
            </el-input>
          </el-form-item>
          <el-form-item label="美金单价" prop="usd_price">
            <el-input
              v-model="mtrlForm.usd_price"
              @input="mtrlForm.usd_price = helper.keepTNum1(mtrlForm.usd_price)"
              @blur="mtrlForm.usd_price = helper.calcPrice(mtrlForm.usd_price, 7, 1000000)"
              maxlength="11"
              show-word-limit
              placeholder="请填写美金单价"
            >
            </el-input>
          </el-form-item>
          <el-form-item
            label="物料克重"
            prop="mtrl_weight"
            :rules="mtrlForm.mtrl_type === 0 ? [{ required: true }] : [{ required: false }]"
          >
            <el-input
              v-model="mtrlForm.mtrl_weight"
              @input="mtrlForm.mtrl_weight = helper.keepTNum1(mtrlForm.mtrl_weight)"
              @blur="mtrlForm.mtrl_weight = helper.retain1(mtrlForm.mtrl_weight, 2, 10000)"
              maxlength="7"
              show-word-limit
              placeholder="请填写物料克重"
            >
              <template slot="append">克</template>
            </el-input>
          </el-form-item>
          <el-form-item
            label="物料规格"
            prop="mtrl_spec"
            :rules="mtrlForm.mtrl_type === 0 ? [{ required: false }] : [{ required: true, trigger: ['blur', 'change'], message: ' ' }]"
          >
            <el-input
              type="textarea"
              @change="mtrlSpecChange"
              rows="1"
              v-model="mtrlForm.mtrl_spec"
              maxlength="50"
              show-word-limit
              placeholder="请填写物料规格"
            ></el-input>
          </el-form-item>
          <el-form-item label="专属客户" prop="cust_name">
            <el-input disabled v-model="mtrlForm.cust_name" show-word-limit placeholder="请填写专属客户" @click.native="custImport()">
              <template slot="append">
                <el-link type="primary">选择</el-link>
              </template>
              {{ mtrlForm.cust_name }}
            </el-input>
          </el-form-item>
          <el-dialog :title="CustTitle" :visible.sync="MtrlFlag" width="70%">
            <MtrlEditCust @custID="custID" @custName="custName" @cancelCust="cancelCust" @custCancel="custCancel"></MtrlEditCust>
          </el-dialog>
        </el-col>
        <el-col :md="8">
          <el-form-item
            v-if="mtrlForm.mtrl_type == 0"
            label="潘通色号"
            prop="mtrl_color"
            :rules="mtrlForm.mtrl_type == 0 ? [{ required: true }] : [{ required: false }]"
          >
            <el-tooltip class="item" effect="dark" content="仅限四位数字" placement="top-start">
              <el-input
                class="mtrlcolor"
                v-model="mtrlForm.mtrl_color"
                @input="mtrlColorInput"
                show-word-limit
                placeholder="仅限四位数字"
                @change="clickSyncColor"
              >
                <template slot="append"> <span v-if="mtrlForm.mtrl_color != ''">C</span></template>
              </el-input>
            </el-tooltip>
          </el-form-item>
          <el-form-item label="颜色大类" prop="colr_class" class="itemBtn">
            <el-select v-model="mtrlForm.colr_class" placeholder="请选择颜色大类" filterable :disabled="mtrlForm.mtrl_type === 0">
              <el-option v-for="item in optnColorList" :key="item.param1" :label="item.param1" :value="item.param1"> </el-option>
            </el-select>
            <!-- <el-button size="mini" type="info" :disabled="mtrlForm.mtrl_color.length===0" @click="clickSyncColor">同步</el-button> -->
          </el-form-item>

          <el-form-item label="供应商户" prop="supp_id">
            <el-select disabled v-model="mtrlForm.supp_id" placeholder="请选择供应商" clearable filterable @visible-change="suppChange">
              <el-option v-for="item in suppType" :key="item.supp_id" :label="item.supp_name" :value="item.supp_id"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="物料单位" prop="mtrl_unit">
            <el-select v-model="mtrlForm.mtrl_unit" placeholder="请选择物料类型" clearable>
              <el-option v-for="item in mtrlTypeGroupOpt" :key="item.id" :label="item.param1" :value="item.param1"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="物料毛高" prop="mtrl_thick" :rules="mtrlForm.mtrl_type == 0 ? [{ required: true }] : [{ required: false }]">
            <el-input
              v-model="mtrlForm.mtrl_thick"
              @input="mtrlForm.mtrl_thick = helper.keepTNum1(mtrlForm.mtrl_thick)"
              @blur="mtrlForm.mtrl_thick = helper.retain1(mtrlForm.mtrl_thick, 2, 1000)"
              maxlength="6"
              show-word-limit
              placeholder="请填写物料毛高"
            >
              <template slot="append">毫米</template>
            </el-input>
          </el-form-item>
          <el-form-item label="物料成分" prop="mtrl_comp">
            <!-- <el-input v-model="mtrlForm.mtrl_comp" maxlength="30" show-word-limit placeholder="请填写物料成分"></el-input> -->
            <el-select v-model="mtrlForm.mtrl_comp" placeholder="请选择物料成分" clearable filterable>
              <el-option v-for="item in compType" :key="item.id" :label="item.param1" :value="item.param1"> </el-option>
            </el-select>
          </el-form-item>
          <!-- :rules="mtrlForm.mtrl_width == 0 ?  [{ required: false }] : [{required:true}]"  -->
          <el-form-item label="物料门幅" prop="mtrl_width" :rules="mtrlForm.mtrl_type == 0 ? [{ required: true }] : [{ required: false }]">
            <el-input
              v-model="mtrlForm.mtrl_width"
              maxlength="9"
              show-word-limit
              @blur="mtrlForm.mtrl_width = helper.calcPrice(mtrlForm.mtrl_width, 4, 10000)"
              @input="mtrlForm.mtrl_width = helper.keepTNum1(mtrlForm.mtrl_width)"
              placeholder="请填写物料门幅"
            >
              <template slot="append">米</template>
            </el-input>
          </el-form-item>

          <el-form-item label="规格英文名" prop="mtrl_espec">
            <el-input
              type="textarea"
              rows="1"
              v-model="mtrlForm.mtrl_espec"
              maxlength="50"
              show-word-limit
              placeholder="请填写规格英文名"
            ></el-input>
          </el-form-item>
          <el-form-item label="其他备注" prop="mtrl_remark">
            <el-input
              type="textarea"
              rows="1"
              v-model="mtrlForm.mtrl_remark"
              maxlength="150"
              show-word-limit
              placeholder="请填写物料备注"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <div class="vd_solidDiv"></div>
      <el-row>
        <el-col :md="7">
          <div class="vd_hidden">111</div>
        </el-col>
        <el-col :md="16">
          <div>
            <div class="vd_but vg_mb_8">
              <el-button plain size="small" @click="addRow()">新增</el-button>
            </div>
            <MtrlImport
              :isShow="isShow"
              @checkboxChange="checkboxChange"
              @inputChange="inputChange"
              @selectChange="selectChange"
              @deleteClick="deleteClick"
              :mtrlForm="mtrlForm"
            ></MtrlImport>
          </div>
        </el-col>
      </el-row>
      <el-row class="vg_mt_16 vd_bortop">
        <el-col>
          <inputUser :isCookie="true" ref="userData"></inputUser>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { mtrlAPI } from '@api/modules/mtrl';
import { optnAPI } from '@api/modules/optn';
import { suppAPI } from '@api/modules/supp';
import { imgeAPI } from '@api/modules/imge';
import { colrAPI } from '@/api/modules/colr.js';
import addHeader from '@/views/component/addHeader';
import helper from '@assets/js/helper';
import inputUser from '@/views/component/inputUser';
import imgPopover from '@/views/component/imgPopover';
import MtrlImport from './Componet/MtrlImport.vue';
import MtrlEditCust from './Componet/MtrlEditCust.vue';

export default {
  name: 'mtrlAddMain',
  components: {
    addHeader,
    inputUser,
    imgPopover,
    MtrlImport,
    MtrlEditCust
  },
  data() {
    return {
      rules: {
        mtrl_no: [{ required: true, trigger: ['blur', 'change'], message: ' ' }],
        mtrl_name: [{ required: true, trigger: 'blur', message: ' ' }],
        mtrl_type: [{ required: true, trigger: 'change', message: ' ' }],
        mtrl_unit: [{ required: true, trigger: 'change', message: ' ' }],
        mtrl_thick: [{ required: true, trigger: ['blur', 'change'], message: ' ' }],
        mtrl_comp: [{ required: true, trigger: 'blur', message: ' ' }],
        mtrl_stock_limit: [{ required: true, trigger: 'blur', message: ' ' }],
        mtrl_prod_price: [{ required: true, trigger: 'blur', message: ' ' }],
        mtrl_width: [{ required: true, trigger: ['blur', 'change'], message: ' ' }],
        mtrl_color: [{ required: true, trigger: ['blur', 'change'], message: ' ' }],
        supp_id: [{ required: true, trigger: 'change', message: ' ' }],
        mtrl_weight: [{ required: true, trigger: 'blur', message: ' ' }]
        // colr_class: [{ required: true, trigger: "change", message: " " }],
      },
      mtrlForm: {
        mtrl_no: '',
        mtrl_name: '',
        mtrl_spec_en: '',
        mtrl_type: 0,
        mtrl_spec: '',
        mtrl_espec: '',
        mtrl_color: '',
        mtrl_weight: '',
        mtrl_width: null,
        mtrl_unit: '',
        imge_id: null,
        imge_url: '',
        imge_urlO: '',
        mtrl_thick: null,
        mtrl_comp: null,
        mtrl_stock_limit: 0,
        mtrl_prod_price: '0.0000',
        mtrl_remark: '',
        cust_id: null,
        cust_name: null,
        supp_id: '',
        user_id: null,
        stff_id: null,
        dept_id: null,
        usd_price: null,
        status: 0,
        key: 0,
        colr_class: null, //颜色大类
        mtrl_info_list: [
          {
            supp_id: null,
            mtrl_prod_price: '0.0000',
            mtrl_info_checked: true,
            key: 0
          }
        ]
      },
      warningTitle: '',
      uploadUrl: helper.modePrepend(imgeAPI.uploadImage),
      dataBody: {
        file: /\.(png|jpg|gif|jpeg)$/,
        cut: true,
        keep: false,
        x: 400,
        y: 400
      },
      mtrlType: [
        { id: 0, label: '原面料(The Original Fabric)' },
        { id: 1, label: '辅料(Ingredients)' },
        { id: 2, label: '包材(Packing Materials)' }
      ],
      mtrlTypeGroupOpt: [],
      suppType: [],
      compType: [],
      optnColorList: [],
      checkedIndex: 0,
      isShow: true,
      MtrlFlag: false,
      CustTitle: '添加物料专属客户',
      imgTypeFlag: true
    };
  },
  watch: {},
  created() {
    this.initData();
  },
  methods: {
    initData() {
      this.getMtrlType();
      this.getCompType();
      this.getSuppsList();
      this.getOptnColor();
      this.getContry();
    },
    //获取颜色大类option
    getOptnColor() {
      get(optnAPI.getAllContent, { perm_id: 10010 })
        .then(res => {
          if (res.data.code === 0) {
            this.optnColorList = res.data.data;
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    //提交表单
    submit(formName) {
      this.$confirm('确认提交?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$refs[formName].validate(valid => {
            if (valid) {
              this.saveInfo();
            } else {
              console.log('error submit!');
              this.$message.warning('请把必填内容填写完整！');
              return false;
            }
          });
        })
        .catch(() => {});
    },
    // 保存
    saveInfo() {
      let mtrlForm = JSON.parse(JSON.stringify(this.mtrlForm));
      if (mtrlForm.mtrl_type == 0) {
        mtrlForm.mtrl_color += 'C';
        mtrlForm.mtrl_no += mtrlForm.mtrl_color;
      }
      const staffForm = this.$refs.userData.getData();
      mtrlForm = Object.assign(mtrlForm, staffForm);
      if (mtrlForm.colr_class) {
        mtrlForm.mtrl_name = mtrlForm.colr_class + mtrlForm.mtrl_name;
      }
      for (let i in mtrlForm.mtrl_info_list) {
        if (mtrlForm.mtrl_info_list[i].mtrl_info_checked) {
          mtrlForm.mtrl_info_list[i].mtrl_info_checked = 1;
        } else if (!mtrlForm.mtrl_info_list[i].mtrl_info_checked) {
          mtrlForm.mtrl_info_list[i].mtrl_info_checked = 0;
        }
      }
      post(mtrlAPI.addMtrl, mtrlForm)
        .then(res => {
          if (res.data.code === 0) {
            this.$message({
              type: 'success',
              message: '保存成功'
            });
            const permId = this.$route.query.perm_id;
            this.jump(`/mtrl_edit?perm_id=${permId}&form_id=${res.data.data.form_id}`);
            this.warningTitle = false;
            this.resetForm('mtrlForm');
          } else if (res.data.code === 7) {
            this.$message({
              type: 'error',
              message: '物料编号重复'
            });
          } else if (res.data.code === 888) {
            let mg = res.data.msg;
            this.warningTitle = mg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
            if (res.data.msg === '物料编号格式输入错误!') {
              this.mtrlForm.mtrl_no = null;
            } else {
              this.mtrlForm.mtrl_no = null;
              this.mtrlForm.mtrl_color = null;
              this.mtrlForm.mtrl_width = null;
              this.mtrlForm.mtrl_thick = null;
            }
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
            // this.initData();
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    //取消
    cancel() {
      this.$confirm('确定取消新增?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      })
        .then(() => {
          this.resetForm('mtrlForm');
          this.$router.go(-1);
          this.$message({
            type: 'info',
            message: '已取消新增!'
          });
        })
        .catch(() => {});
    },
    //打开专属客户弹窗
    custImport() {
      this.MtrlFlag = true;
    },
    //关闭专属客户弹窗
    custCancel() {
      this.MtrlFlag = false;
    },
    // custID子传父值
    custID(val) {
      if (val) {
        this.mtrlForm.cust_id = val.join(',');
      } else {
        this.mtrlForm.cust_id = null;
      }
    },
    custName(val) {
      if (val) {
        this.mtrlForm.cust_name = val.join(',');
      } else {
        this.mtrlForm.cust_name = null;
      }
    },
    // 取消
    cancelCust() {
      this.MtrlFlag = false;
    },
    // 新增字表数据
    addRow() {
      let temp = {};
      if (this.mtrlForm.supp_id) {
        temp.supp_id = this.mtrlForm.supp_id;
      } else {
        temp.supp_id = null;
      }
      if (this.mtrlForm.mtrl_prod_price) {
        temp.mtrl_prod_price = this.mtrlForm.mtrl_prod_price;
      } else {
        temp.mtrl_prod_price = '0.0000';
      }
      temp.mtrl_info_checked = false;
      temp.key = this.mtrlForm.mtrl_info_list.length;
      this.mtrlForm.mtrl_info_list.push(temp);
    },
    // 删除子表数据
    deleteClick(val) {
      if (this.mtrlForm.mtrl_info_list.length <= 1) {
        this.$message({
          type: 'warning',
          message: '至少保留一条'
        });
      } else {
        let flag = false;
        if (this.mtrlForm.mtrl_info_list[val.key].mtrl_info_checked) {
          flag = true;
        }
        this.mtrlForm.mtrl_info_list.splice(val.key, 1);
        for (let i in this.mtrlForm.mtrl_info_list) {
          this.mtrlForm.mtrl_info_list[i].key = i;
        }
        if (flag) {
          this.mtrlForm.mtrl_info_list[0].mtrl_info_checked = true;
          this.checkedIndex = 0;
          this.mtrlForm.mtrl_prod_price = this.mtrlForm.mtrl_info_list[0].mtrl_prod_price;
          this.mtrlForm.supp_id = this.mtrlForm.mtrl_info_list[0].supp_id;
        } else {
          for (let i in this.mtrlForm.mtrl_info_list) {
            if (this.mtrlForm.mtrl_info_list[i].mtrl_info_checked) {
              this.checkedIndex = i;
            }
          }
        }
      }
    },
    // 狭隘拉框更换
    selectChange(val) {
      this.mtrlForm.supp_id = this.mtrlForm.mtrl_info_list[val].supp_id;
    },
    // 价格变动
    inputChange(val) {
      this.mtrlForm.mtrl_prod_price = this.helper.haveFour(this.mtrlForm.mtrl_info_list[val].mtrl_prod_price);
    },
    // 更换复选框
    checkboxChange(val, val1) {
      if (val.mtrl_info_checked) {
        let flag = 0;
        for (let i in this.mtrlForm.mtrl_info_list) {
          if (this.mtrlForm.mtrl_info_list[i].mtrl_info_checked) {
            flag += 1;
          }
        }
        if (flag > 1) {
          let ind = JSON.parse(JSON.stringify(this.checkedIndex));
          this.mtrlForm.mtrl_info_list[ind].mtrl_info_checked = false;
          this.checkedIndex = val1;
          this.mtrlForm.mtrl_prod_price = this.mtrlForm.mtrl_info_list[val1].mtrl_prod_price;
          this.mtrlForm.supp_id = this.mtrlForm.mtrl_info_list[val1].supp_id;
        } else {
          this.checkedIndex = val1;
          this.mtrlForm.mtrl_prod_price = this.mtrlForm.mtrl_info_list[val1].mtrl_prod_price;
          this.mtrlForm.supp_id = this.mtrlForm.mtrl_info_list[val1].supp_id;
        }
      } else {
        let flag = 0;
        for (let i in this.mtrlForm.mtrl_info_list) {
          if (this.mtrlForm.mtrl_info_list[i].mtrl_info_checked) {
            flag += 1;
          }
        }
        if (flag === 0) {
          this.mtrlForm.mtrl_info_list[val1].mtrl_info_checked = true;
          this.mtrlForm.mtrl_prod_price = this.mtrlForm.mtrl_info_list[val1].mtrl_prod_price;
          this.mtrlForm.supp_id = this.mtrlForm.mtrl_info_list[val1].supp_id;
          this.checkedIndex = val1;
        }
      }
    },
    // 商户下拉框下拉
    suppChange() {
      this.getSuppsList();
    },
    // 获取成分
    getCompType() {
      get(optnAPI.getAllContent, { perm_id: 10008 })
        .then(res => {
          if (res.data.code === 0) {
            this.compType = res.data.data;
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 获取单位
    getMtrlType() {
      get(optnAPI.getAllContent, { perm_id: 10005 })
        .then(res => {
          if (res.data.code === 0) {
            this.mtrlTypeGroupOpt = res.data.data;
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 获取供应商信息
    getSuppsList() {
      get(suppAPI.getSuppsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.suppType = res.data.data;
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 获取物料名称名字
    getContry() {
      get(optnAPI.getOptnByPermId, { perm_id: 10013 })
        .then(res => {
          if (res.data.code === 0) {
            this.mtrlNameList = res.data.data.form.optn_cntt_list;
          }
        })
        .catch(() => {});
    },
    // 成功上传
    handleAvatarSuccess(res) {
      if (res.code === 0) {
        if (this.imgTypeFlag) return this.$message.warning('仅支持jpg、png、jpeg格式的图片');
        let updateTime = JSON.parse(JSON.stringify(new Date().getTime()));
        let urlEng = JSON.parse(JSON.stringify(res.data.imge_url));
        this.mtrlForm.imge_id = res.data.imge_id;
        this.mtrlForm.imge_url = this.helper.picUrl(urlEng, 's', updateTime);
        this.mtrlForm.imge_urlO = this.helper.picUrl(urlEng, 'l', updateTime);
      } else {
        let mg = res.msg;
        let tp = 'error';
        this.$message({ message: mg, type: tp });
      }
    },
    // 上传头像前
    beforeAvatarUpload(res) {
      this.imgTypeFlag = false;
      let str = res.name.substr(res.name.indexOf('.') + 1, res.name.length);
      let imgTypeList = ['jpg', 'png', 'jpeg'];
      if (imgTypeList.indexOf(str) === -1) this.imgTypeFlag = true;
    },
    //表单重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.mtrlForm.imge_url = null;
      this.mtrlForm.imge_urlO = null;
      this.mtrlForm.imge_id = null;
    },
    mtrlNoInput() {
      this.mtrlForm.mtrl_no = this.helper.keepEnglish(this.mtrlForm.mtrl_no).toUpperCase();
      this.mtrlForm.mtrl_no = this.mtrlForm.mtrl_no.substr(0, 2);
    },
    // 计算物料英文名
    mtrlNameChange() {
      let flag = false;
      for (let i = 0; i < this.mtrlNameList.length; i++) {
        if (this.mtrlForm.mtrl_name === this.mtrlNameList[i].param1) {
          this.mtrlForm.mtrl_spec_en = this.mtrlNameList[i].param2;
          flag = true;
        }
      }
      if (!flag) {
        this.$message.warning('未匹配到物料英文名');
      }
    },
    // 计算规格英文名
    mtrlSpecChange() {
      for (let i = 0; i < this.mtrlNameList.length; i++) {
        if (this.mtrlForm.mtrl_spec.indexOf(this.mtrlNameList[i].param1) !== -1) {
          let str = this.mtrlForm.mtrl_spec.substring(0, this.mtrlForm.mtrl_spec.indexOf(this.mtrlNameList[i].param1));
          let str1 = this.mtrlForm.mtrl_spec.substring(
            this.mtrlForm.mtrl_spec.indexOf(this.mtrlNameList[i].param1) + this.mtrlNameList[i].param1.length,
            this.mtrlForm.mtrl_spec.length
          );
          let str2 = this.mtrlNameList[i].param2;
          this.mtrlForm.mtrl_espec = str + str2 + str1;
        }
      }
    },
    mtrlColorInput() {
      this.mtrlForm.mtrl_color = this.helper.keepTNum(this.mtrlForm.mtrl_color);
      this.mtrlForm.mtrl_color = this.mtrlForm.mtrl_color.substr(0, 4);
    },
    typeChange() {
      if (this.mtrlForm.mtrl_type != 0) {
        this.mtrlForm.mtrl_color = '';
        this.mtrlForm.colr_class = '';
      } else {
        this.mtrlForm.colr_class = null;
      }
    },
    //同步颜色 大类
    clickSyncColor() {
      get(colrAPI.getColrClassByPantone, {
        colr_pantone: this.mtrlForm.mtrl_color + 'C'
      })
        .then(res => {
          if (res.data.code === 0) {
            this.mtrlForm.colr_class = res.data.data.form.colr_class;
            this.$message.success('同步' + res.data.msg);
            return;
          } else if (res.data.code === 2) {
            this.$message.warning('未匹配到该颜色大类');
            return;
          }
          this.$message.warning(res.data.msg);
        })
        .catch(res => {
          this.$message.warning(res.data.msg);
        });
    }
  }
};
</script>

<style scoped lang="scss">
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader ::v-deep .el-upload--text {
  width: 136px;
  height: 136px;
}
.avatar-uploader .el-upload:hover {
  border-color: $color-primary;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: $color-uploader-icon;
  width: 136px;
  height: 136px;
  line-height: 136px;
  text-align: center;
}
.avatar {
  width: 136px;
  height: 136px;
  display: block;
}

.vd_bortop {
  border-top: #dcdfe6 solid 1px;
  padding-top: 20px;
}
.vd_cussor {
  cursor: default;
}
::v-deep .mtrlNo .el-input-group__append {
  width: 70%;
  padding: 0;
}
::v-deep .mtrlcolor .el-input-group__append {
  width: 70%;
  padding: 0;
}
::v-deep .el-input-group__append {
  color: #000000;
}
.vd_color_red {
  color: red;
}
::v-deep .itemBtn .el-form-item__content {
  display: flex;
}

.vd_solidDiv {
  height: 1px;
  border: none;
  border-top: 1px dashed #dcdfe6;
  // background-color: #DCDFE6;
  margin: 10px 0 20px 0;
}
.vd_hidden {
  visibility: hidden;
}
</style>
